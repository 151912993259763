import React from "react";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { Loader } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { FaceLivenessDetector } from "@aws-amplify/ui-react-liveness";
import { View, Button } from "@aws-amplify/ui-react";

function FaceLiveness({ faceLivenessAnalysis, bearer, callback }) {
  const [loading, setLoading] = React.useState(true);
  const [sessionId, setSessionId] = React.useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(null);
  const [mobileMode, setMobileMode] = React.useState('user');
  const [cameras, setCameras] = React.useState([]);
  const [showCameraSelection, setShowCameraSelection] = React.useState(false);

  async function requestCameraPermissions() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop()); // Immediately stop the stream after permission is granted
    } catch (error) {
      console.error("Camera permission denied:", error);
      Swal.fire("Camera access is required!", "Please allow camera access to continue.", "error");
    }
  }

  useEffect(() => {
    requestCameraPermissions();
  }, []);

  const fetchCreateLiveness = async () => {
    const response = await fetch(
      "https://backend.katsina-revpay.com/api/v1/initiate-liveness",
      {
        headers: {
          Authorization: "Bearer " + bearer,
        },
      }
    );
    const data = await response.json();
    setSessionId(data.data.session_id);
    setLoading(false);
  };

  function startObserving() {
    let containerObserver;
    let videoObserver;

    const handleVideoLoaded = () => {
      console.log('Video data has loaded.');
    };

    const attachVideoObserver = (container) => {
      const existingVideo = container.querySelector('video');
      if (existingVideo) {
        console.log('Found an existing video element.');
        existingVideo.addEventListener('loadeddata', handleVideoLoaded);
      }

      videoObserver = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(async (node) => {
              console.log('Node added:', node.nodeName);
              if (node.nodeName === 'CANVAS') {
                console.log('Video element added to container.');
                const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
                if (isMobile) {
                  await navigator.mediaDevices.getUserMedia({ video: { facingMode: mobileMode } }).then(stream => document.querySelector('video').srcObject = stream);
                } else {
                  await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: selectedDeviceId } } }).then(stream => document.querySelector('video').srcObject = stream);
                }
                videoObserver.disconnect();
                node.addEventListener('loadeddata', handleVideoLoaded);
                
                document.getElementsByClassName('amplify-liveness-cancel-container')[0].addEventListener('click', () => {
                  startObserving();
                });
                return;
              }
            });
          }
        }
      });

      videoObserver.observe(container, { childList: true, subtree: true });
    };

    let container = document.querySelector('div[data-testid="liveness-detector"]');
    if (container) {
      console.log('Found container immediately.');
      attachVideoObserver(container);
    } else {
      containerObserver = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            container = document.querySelector('div[data-testid="liveness-detector"]');
            if (container) {
              console.log('Container found via MutationObserver.');
              attachVideoObserver(container);
              containerObserver.disconnect();
              break;
            }
          }
        }
      });
      containerObserver.observe(document.body, { childList: true, subtree: true });
    }

    return () => {
      if (containerObserver) containerObserver.disconnect();
      if (videoObserver) videoObserver.disconnect();
    };
  }

  useEffect(() => {
    startObserving();
  });

  useEffect(() => {
    fetchCreateLiveness();
  }, []);

  useEffect(() => {
    const checkCamera = async () => {
      if (!(/Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent))) {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevices = devices.filter(d => d.kind === "videoinput");
          setCameras(videoDevices);

          const savedDeviceId = localStorage.getItem("preferredCameraDeviceId");
          if (savedDeviceId) {
            const deviceExists = videoDevices.some(d => d.deviceId === savedDeviceId);
            if (deviceExists) {
              setSelectedDeviceId(savedDeviceId);
            } else {
              if (videoDevices.length > 0) setShowCameraSelection(true);
            }
          } else {
            if (videoDevices.length > 1) {
              setShowCameraSelection(true);
            } else if (videoDevices.length === 1) {
              const defaultDeviceId = videoDevices[0].deviceId;
              localStorage.setItem("preferredCameraDeviceId", defaultDeviceId);
              setSelectedDeviceId(defaultDeviceId);
            }
          }
        } catch (error) {
          console.error("Error enumerating cameras:", error);
        }
      } else {
        try {
          const devices = await navigator.mediaDevices.enumerateDevices();
          const videoDevices = devices.filter(d => d.kind === "videoinput");
          const defaultDeviceId = videoDevices[0].deviceId;
          localStorage.setItem("preferredCameraDeviceId", defaultDeviceId);
          setSelectedDeviceId(defaultDeviceId);
        } catch (error) {
          console.error("Error enumerating cameras:", error);
        }
      }
    };

    if (sessionId) checkCamera();
  }, [sessionId]);

  useEffect(() => {
    const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (!isMobile) {
      if (showCameraSelection && cameras.length > 0) {
        const cameraOptions = cameras.map((camera, index) => ({
          deviceId: camera.deviceId,
          label: camera.label || `Camera ${index + 1}`,
        }));

        Swal.fire({
          title: "Select Preferred Camera",
          html: `
            <select id="cameraSelect" class="form-control">
              ${cameraOptions.map(option => `
                <option value="${option.deviceId}">${option.label}</option>
              `).join("")}
            </select>
          `,
          confirmButtonText: "Confirm",
          showCancelButton: true,
          preConfirm: () => {
            const select = document.getElementById("cameraSelect");
            return select.value;
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const deviceId = result.value;
            localStorage.setItem("preferredCameraDeviceId", deviceId);
            setSelectedDeviceId(deviceId);
          } else if (cameras.length > 0) {
            const deviceId = cameras[0].deviceId;
            localStorage.setItem("preferredCameraDeviceId", deviceId);
            setSelectedDeviceId(deviceId);
          }
          setShowCameraSelection(false);
        });
      }
    }
  }, [showCameraSelection, cameras]);

  const handleTryAgain = () => {
    setLoading(true);
    setSessionId(null);
    setSelectedDeviceId(null);
    fetchCreateLiveness();
  };

  const handleAnalysisComplete = async () => {
    const response = await fetch(
      "https://backend.katsina-revpay.com/api/v1/liveness-results",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + bearer,
        },
        body: JSON.stringify({ sessionid: sessionId }),
      }
    );
    const data = await response.json();
    const backto = callback ? callback : false;

    if (data.message) {
      Swal.fire({
        title: data.message,
        icon: "error",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = backto || "https://katsina-revpay.com/";
        }
      });
    } else {
      Swal.fire({
        title: "Registration Successful",
        html: `Your Unique ID is <b>${data.data.individual_id}</b>`,
        icon: "success",
        confirmButtonColor: "#198754",
        confirmButtonText: 'Ok!',
      }).then(() => {
        window.location.href = "https://katsina-revpay.com/" + backto;
      });
    }
  };

  return (
    <>
      {sessionId && selectedDeviceId ? (
        <div style={{ position: "relative" }}>
          <Button
            onClick={() => setShowCameraSelection(true)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            Switch Camera
          </Button>
          <FaceLivenessDetector
            sessionId={sessionId}
            region="us-west-2"
            onAnalysisComplete={handleAnalysisComplete}
            onError={(error) => console.error(error)}
            components={{
              ErrorView: ({ children }) => (
                <View
                  as="div"
                  backgroundColor="var(--amplify-colors-white)"
                  borderRadius="6px"
                  border="1px solid var(--amplify-colors-black)"
                  maxWidth="100%"
                  padding="1rem"
                  width="20rem"
                  textAlign="center"
                >
                  {children}
                  <br />
                  <Button onClick={handleTryAgain} variation="primary">
                    Try Again
                  </Button>
                </View>
              ),
            }}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default FaceLiveness;